<template>
  <div class="flex-column">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <!-- <base-search :searchList="$bbglLstjSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"></base-search> -->
        <el-input @keyup.enter.native="handleFilter" size="mini" class="filter-item top_search_css" v-model.trim="listQuery.key" :placeholder="'用户名称/表名称/表编号'" clearable style="width: 200px !important"> </el-input>
        <el-button class="filter-item" size="mini" type="primary" plain @click="chooseDate('today')">今天</el-button>
        <el-button class="filter-item" size="mini" type="primary" plain @click="chooseDate('yesterday')">昨天</el-button>
        <el-button class="filter-item" size="mini" type="primary" plain @click="chooseDate('sevenTime')">最近七天</el-button>
        <el-date-picker v-model="listQuery.timer" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="margin:0 10px;width: 280px !importantheight: 33px;vertical-align: middle;"> </el-date-picker>
        <el-button class="filter-item" size="mini" type="primary" v-waves icon="el-icon-search" @click='handleFilter'>搜索</el-button>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :Treedata="orgsTree" :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" :hasOperation="true" @checkDetails="checkDetails" :tableTotal="tableTotal"></basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
      <!-- <el-row style="height: 100%;" :gutter="8" >
        <el-col :span="4" style="height: 100%;">
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :Treedata="orgsTree" :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys"></basic-tree >
          </el-card>
        </el-col>
        <el-col :span="20" class="zzb" style="height: 100%;" id="table_box_height">
          <div class="bg-white">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" :hasOperation="true" @checkDetails="checkDetails" :tableTotal="tableTotal"></basic-table >
          </div>
        </el-col>
      </el-row> -->
    </div>

    <!-- 详情弹窗 -->
    <el-dialog class="dialog-mini user-dialog" title="漏损详情" :visible.sync="lsxqDialog" :width="'70%'" @close="lsxqDialog = false">
      <div>
        <basic-table :tableHeight="600" :tableHeaderList="lsxqHeaderList" :tableData="lsxqTableData" :listQuery="listQueryLsxq" :hasOperation="false" :hasPagination="false"></basic-table >
      </div>
      <div style="text-align:right;" slot="footer">
        <el-button size="small" type="danger" @click="lsxqDialog = false">关闭</el-button>
      </div>
    </el-dialog>

  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import basicTable from './table'//表格组件
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      basicTable,
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          timer:[],
          treeIds:[],
          key: '',
          beginTime:'',
          endTime:'',
        },
        tableData:[],//表格1数据
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        lsxqDialog:false,//流程图弹窗
        lsxqHeaderList:this.$bbglLstjTableHeader2,// 流程图列表列定义
        lsxqTableData:[],//流程图列表数据
        listQueryLsxq:{
          page: 1,
          limit: 999,
          meterId: '',
          beginTime:'',
          endTime:'',
        },
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
      }
    },
    watch: {
      
    },
    computed: {
      orgsTree() {
        return this.$store.state.AllArea;
      },
    },
    filters: {
      
    },
    created() {
      
    },
    mounted() {
      // 计算table高度
      // this.$nextTick(()=>{
      //   let ele=document.getElementById("table_box_height");
      //   this.tableHeight=(ele.offsetHeight-50)/2
      //   window.addEventListener('resize', () => { 
      //     let ele=document.getElementById("table_box_height");
      //     this.tableHeight=(ele.offsetHeight-50)/2
      //     this.$forceUpdate()
      //   });
      // })
      var today = new Date();
      var yesterday = this.$basicFun.getDay(-1);
      this.listQuery.timer = [yesterday,today]
      this.getList()
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
      })

      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      //选择时间
      chooseDate(time){
        var today = new Date();
        var yesterday = this.$basicFun.getDay(-1);
        var beforeYesterday = this.$basicFun.getDay(-2);
        var sevenTime = this.$basicFun.getDay(-7);
        if(time == 'today'){
          this.listQuery.timer = [yesterday,today]
        }
        if(time == 'yesterday'){
          this.listQuery.timer = [beforeYesterday,yesterday]
        }
        if(time == 'sevenTime'){
          this.listQuery.timer = [sevenTime,today]
        }
        this.handleFilter()
      },
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        // console.log(data)
        // this.CheckedKey = data
        // var s = this.CheckedKey.length
        // this.tableData = this.$store.state.tableDataSearch.slice(0,s)
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            this.handleDelete(this.tableChooseRow)
            break
          case 'btnAdd'://添加
            
            break
          default:
            break
        }
      },
      // 获取数据
      getList() {
        if(this.listQuery.timer){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.timer[0],'yyyy-MM-dd');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.timer[1],'yyyy-MM-dd');
        }else{
          this.listQuery.beginTime = this.listQuery.endTime = ''
        }
        basicDataApi.lstjGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
        })
        
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      //查看详情
      checkDetails(row){
        this.listQueryLsxq.meterId = row.meterId
        this.listQueryLsxq.beginTime = this.listQuery.beginTime
        this.listQueryLsxq.endTime = this.listQuery.endTime
        basicDataApi.lstjDetailsGetList(this.listQueryLsxq).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.lsxqHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.lsxqTableData = response.data
            this.lsxqDialog = true
          }else{
            this.lsxqHeaderList = []
            this.lsxqTableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },
    },
  }

</script>

<style lang="scss" scoped>
  .zzb{
    display: flex;
    flex-direction: column;
  }
  .line{
    height: 20px;
    width: 100%;
    background: #ccc;
  }
</style>
