var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("el-input", {
              staticClass: "filter-item top_search_css",
              staticStyle: { width: "200px !important" },
              attrs: {
                size: "mini",
                placeholder: "用户名称/表名称/表编号",
                clearable: "",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleFilter.apply(null, arguments)
                },
              },
              model: {
                value: _vm.listQuery.key,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.listQuery,
                    "key",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "listQuery.key",
              },
            }),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { size: "mini", type: "primary", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.chooseDate("today")
                  },
                },
              },
              [_vm._v("今天")]
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { size: "mini", type: "primary", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.chooseDate("yesterday")
                  },
                },
              },
              [_vm._v("昨天")]
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                attrs: { size: "mini", type: "primary", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.chooseDate("sevenTime")
                  },
                },
              },
              [_vm._v("最近七天")]
            ),
            _c("el-date-picker", {
              staticStyle: {
                margin: "0 10px",
                width: "280px !importantheight: 33px",
                "vertical-align": "middle",
              },
              attrs: {
                type: "daterange",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
              },
              model: {
                value: _vm.listQuery.timer,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "timer", $$v)
                },
                expression: "listQuery.timer",
              },
            }),
            _c(
              "el-button",
              {
                directives: [{ name: "waves", rawName: "v-waves" }],
                staticClass: "filter-item",
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-search",
                },
                on: { click: _vm.handleFilter },
              },
              [_vm._v("搜索")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "app-container flex-item" }, [
        _c("div", { ref: "dragBox", staticClass: "dragBox" }, [
          _c(
            "div",
            { staticClass: "dragBox_left" },
            [
              _c(
                "el-card",
                {
                  staticClass: "body-small",
                  staticStyle: { height: "100%", overflow: "auto" },
                  attrs: { shadow: "never" },
                },
                [
                  _c("basic-tree", {
                    attrs: { Treedata: _vm.orgsTree, isShowCheck: true },
                    on: {
                      handleNodeClick: _vm.handleNodeClick,
                      getCheckedKeys: _vm.getCheckedKeys,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dragBox_resize", attrs: { title: "收缩侧边栏" } },
            [_vm._v(" ⋮ ")]
          ),
          _c("div", { staticClass: "dragBox_mid" }, [
            _c(
              "div",
              {
                staticClass: "bg-white",
                staticStyle: { height: "100%" },
                attrs: { id: "table_box_height" },
              },
              [
                _c("basic-table", {
                  attrs: {
                    tableHeight: _vm.tableHeight,
                    tableHeaderList: _vm.firstHeaderList,
                    tableData: _vm.tableData,
                    listQuery: _vm.listQuery,
                    hasOperation: true,
                    tableTotal: _vm.tableTotal,
                  },
                  on: {
                    pagination: _vm.handleCurrentChange,
                    checkDetails: _vm.checkDetails,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-mini user-dialog",
          attrs: { title: "漏损详情", visible: _vm.lsxqDialog, width: "70%" },
          on: {
            "update:visible": function ($event) {
              _vm.lsxqDialog = $event
            },
            close: function ($event) {
              _vm.lsxqDialog = false
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("basic-table", {
                attrs: {
                  tableHeight: 600,
                  tableHeaderList: _vm.lsxqHeaderList,
                  tableData: _vm.lsxqTableData,
                  listQuery: _vm.listQueryLsxq,
                  hasOperation: false,
                  hasPagination: false,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.lsxqDialog = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }